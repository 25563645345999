import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const GeoIPCrontab = makeShortcode("GeoIPCrontab");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="warning" mdxType="Alert">
      <p>{`In January 2024, we began using R2 presigned URLs for all database downloads.
You should make sure that your HTTP client follows redirects and there are no
proxy or firewall settings that would block requests to the host we are
redirecting to. We will redirect requests using HTTPS on the following
hostname:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`mm-prod-geoip-databases.a2649acb697e2c09b632799562c076f2.r2.cloudflarestorage.com`}</inlineCode></li>

      </ul>
      <p>{`Existing database download links will continue to work, they will simply be
redirected.`}</p>
      <p><a {...{
          "href": "/geoip/release-notes/2024#presigned-urls-for-database-downloads",
          "parentName": "p"
        }}>{`Read our release note for more information.`}</a></p>
    </Alert>
    <p>{`There are two methods for automatically updating GeoIP2 and GeoLite binary
databases:`}</p>
    <ol>

      <li {...{
        "parentName": "ol"
      }}>{`Using MaxMind's
`}
        <a {...{
          "href": "https://github.com/maxmind/geoipupdate/releases",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "li"
        }}>{`GeoIP Update program`}</a>
        {`.`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Directly downloading the database.`}</li>

    </ol>
    <p>{`For clients using our binary database format, we highly recommend using GeoIP
Update, but the direct download method is available if necessary. Clients using
the CSV-format databases must use the direct download method.`}</p>
    <div {...{
      "id": "toc-using-geoip-update"
    }}><h2 {...{
        "id": "using-geoip-update",
        "parentName": "div"
      }}>{`Using GeoIP Update`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`MaxMind provides the GeoIP Update program, which performs automatic updates for
both GeoIP2 and GeoLite2 binary databases.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`MaxMind uses R2 presigned URLs for database downloads. Make sure that your
servers can make HTTPS connections to the following hostname:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`mm-prod-geoip-databases.a2649acb697e2c09b632799562c076f2.r2.cloudflarestorage.com`}</inlineCode></li>

      </ul>
      <div {...{
        "id": "toc-1-install-geoip-update",
        "parentName": "div"
      }}><h3 {...{
          "id": "1-install-geoip-update",
          "parentName": "div"
        }}>{`1. Install GeoIP Update`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`Install GeoIP Update. The latest release may be downloaded from
`}
          <a {...{
            "href": "https://github.com/maxmind/geoipupdate/releases",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GitHub Releases`}</a>
          {`. See
`}
          <a {...{
            "href": "https://github.com/maxmind/geoipupdate",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`the installation instructions`}</a>
          {` for more
information. GeoIP Update can also be installed via our
`}
          <a {...{
            "href": "https://hub.docker.com/r/maxmindinc/geoipupdate",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Docker image`}</a>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`If you are using an older version of GeoIP Update, you may need to upgrade to
GeoIP Update 4.x or later version. The 4.x and later versions meet our
requirement for using TLS 1.2 or greater for all requests to our servers to keep
your data secure.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`Please see our `}
          <a {...{
            "href": "/geoip/upgrading-geoip-update",
            "parentName": "p"
          }}>{`upgrade guide`}</a>
          {` for more
information on upgrading from an older version of GeoIP Update.`}</p></div>
      <div {...{
        "id": "toc-2-obtain-geoipconf-with-account-information",
        "parentName": "div"
      }}><h3 {...{
          "id": "2-obtain-geoipconf-with-account-information",
          "parentName": "div"
        }}>{`2. Obtain GeoIP.conf with Account Information`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`Log in to your account portal to download a partially
`}
          <a {...{
            "href": "https://www.maxmind.com/en/accounts/current/license-key/GeoIP.conf",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`pre-filled configuration file`}</a>
          {`
and save it in your configuration directory (e.g., `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`/usr/local/etc/`}</inlineCode>
          {`) as
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP.conf`}</inlineCode>
          {`. You will need to replace the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`YOUR_LICENSE_KEY_HERE`}</inlineCode>
          {` placeholder
with an active license key associated with your MaxMind account. You can see
your license key information on
`}
          <a {...{
            "href": "https://www.maxmind.com/en/accounts/current/license-key",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`your account License Keys page`}</a>
          {`.`}</p>
        <Alert type="warning" mdxType="Alert">
          <p>{`You may also write this file by hand using the template below
(`}
            <strong {...{
              "parentName": "p"
            }}>{`not  recommended`}</strong>
            {`).`}</p>
          <p>{`Note that for `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`geoipupdate`}</inlineCode>
            {` versions less than 2.5.0, use `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`UserId`}</inlineCode>
            {` instead of
`}
            <inlineCode {...{
              "parentName": "p"
            }}>{`AccountID`}</inlineCode>
            {` and `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`ProductIds`}</inlineCode>
            {` instead of `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`EditionIDs`}</inlineCode>
            {`.`}</p>
        </Alert>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-bash",
            "parentName": "pre"
          }}>{`# GeoIP.conf file - used by geoipupdate program to update databases
# from http://www.maxmind.com
AccountID YOUR_ACCOUNT_ID_HERE
LicenseKey YOUR_LICENSE_KEY_HERE
EditionIDs YOUR_EDITION_IDS_HERE
`}</code></pre></div>
      <div {...{
        "id": "toc-3-run-geoip-update",
        "parentName": "div"
      }}><h3 {...{
          "id": "3-run-geoip-update",
          "parentName": "div"
        }}>{`3. Run GeoIP Update`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`Run `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoipupdate`}</inlineCode>
          {`. To fully automate this process on Linux or Unix, use a
crontab file like:`}</p>
        <GeoIPCrontab mdxType="GeoIPCrontab" />
        <p {...{
          "parentName": "div"
        }}>{`This crontab file would run twice a week, and it would email you the results.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`If you are running a firewall, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoipupdate`}</inlineCode>
          {` requires that the DNS and HTTPS
(443) ports be open.`}</p></div>
      <div {...{
        "id": "toc-troubleshooting",
        "parentName": "div"
      }}><h3 {...{
          "id": "troubleshooting",
          "parentName": "div"
        }}>{`Troubleshooting`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`If you receive an error when running GeoIP Update, you can use the verbose
option by adding the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`-v`}</inlineCode>
          {` flag. This will print out each step taken as it runs.
The information provided can often help to clarify issues. Additional
instructions for GeoIP Update can be viewed when running `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`man geoipupdate`}</inlineCode>
          {` on
the command line.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`Please note that GeoIP Update requires current access to database updates to run
properly. If your subscription needs to be renewed, updates can be purchased
while logged into
`}
          <a {...{
            "href": "https://www.maxmind.com/en/accounts/current/people/current",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`your Account Summary page`}</a>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`Updating to the
`}
          <a {...{
            "href": "https://github.com/maxmind/geoipupdate/releases",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`latest release of GeoIP Update`}</a>
          {`
may be required to resolve some errors. If you are not currently using version 4
or higher of GeoIP Update and would like to learn about upgrading to the current
version of the program, read our
`}
          <a {...{
            "href": "/geoip/upgrading-geoip-update",
            "parentName": "p"
          }}>{`guide on how to upgrade to GeoIP Update version 4.x`}</a>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`Starting in 2024, MaxMind began using R2 presigned URLs for database downloads.
If your server is refusing the connection during download, make sure that your
servers can make HTTPS connections to the following hostname:`}</p>
        <ul {...{
          "parentName": "div"
        }}>

          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`mm-prod-geoip-databases.a2649acb697e2c09b632799562c076f2.r2.cloudflarestorage.com`}</inlineCode></li>

        </ul></div></div>
    <div {...{
      "id": "toc-directly-downloading-databases"
    }}><h2 {...{
        "id": "directly-downloading-databases",
        "parentName": "div"
      }}>{`Directly downloading databases`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`This is only recommended for systems unable to use GeoIP Update or for clients
using the CSV-format databases.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`In order to download the databases from a script or program, please use the
permalinks found on the
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/geoip/downloads",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`GeoIP download page`}</a>
        {`.
Please note that you will be redirected from these permalinks because MaxMind
uses R2 presigned URLs for database downloads. You should make sure that your
HTTP client follows redirects and there are no proxy or firewall settings that
would block requests to the host we are redirecting to. We will redirect
requests using HTTPS on the following hostname:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`mm-prod-geoip-databases.a2649acb697e2c09b632799562c076f2.r2.cloudflarestorage.com`}</inlineCode></li>

      </ul>
      <p {...{
        "parentName": "div"
      }}>{`To directly download databases, follow these steps:`}</p>
      <ol {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ol"
        }}>{`In the “Download Links” column, click “Get Permalink(s)” for the desired
database.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Copy the permalink(s) provided in the modal window.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Provide your
`}
          <a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4412951066779-Find-my-Account-ID",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`account ID`}</a>
          {`
and
`}
          <a {...{
            "href": "https://www.maxmind.com/en/accounts/current/license-key",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`your license key`}</a>
          {`
using
`}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Basic_access_authentication",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Basic Authentication`}</a>
          {`
to authenticate.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`If you are using `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`wget`}</inlineCode>
          {` or `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`curl`}</inlineCode>
          {` from a shell script, please be sure to
quote the URL.`}</li>

      </ol>
      <p {...{
        "parentName": "div"
      }}>{`This download will return a compressed file in gzip (for binary) or zip (for
CSV) format, which can be uncompressed using a program like
`}
        <a {...{
          "href": "https://www.7-zip.org/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`7-Zip`}</a>
        {` or `}
        <a {...{
          "href": "https://www.gzip.org/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`gzip`}</a>
        {`.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`For example, using the permalink for the GeoIP2 City CSV database, you can issue
a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`curl`}</inlineCode>
        {` command like the following:`}</p>
      <pre {...{
        "parentName": "div"
      }}><code {...{
          "className": "language-cli",
          "parentName": "pre"
        }}>{`curl -O -J -L -u YOUR_ACCOUNT_ID:YOUR_LICENSE_KEY 'https://download.maxmind.com/geoip/databases/GeoIP2-City-CSV/download?suffix=zip'
`}</code></pre>
      <p {...{
        "parentName": "div"
      }}>{`Or a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`wget`}</inlineCode>
        {` command like the following:`}</p>
      <pre {...{
        "parentName": "div"
      }}><code {...{
          "className": "language-cli",
          "parentName": "pre"
        }}>{`wget --content-disposition --user=YOUR_ACCOUNT_ID --password=YOUR_LICENSE_KEY 'https://download.maxmind.com/geoip/databases/GeoIP2-City-CSV/download?suffix=zip'
`}</code></pre>
      <p {...{
        "parentName": "div"
      }}>{`Where `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`YOUR_ACCOUNT_ID`}</inlineCode>
        {` is a placeholder for your account ID and
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`YOUR_LICENSE_KEY`}</inlineCode>
        {` is a placeholder for your license key.
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4407116112539-Using-License-Keys",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn more about license keys on our knowledge base`}</a>
        {`.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`These examples will save the database to a file called
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`GeoIP2-City-CSV_YYYYMMDD.zip`}</inlineCode>
        {` where YYYYMMDD is the date of the latest release.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`We also maintain example zip files on the corresponding GeoIP database
documentation pages (see
`}
        <a {...{
          "href": "/geoip/docs/databases/city-and-country/#example-files",
          "parentName": "p"
        }}>{`the GeoIP City example file`}</a>
        {`),
and
`}
        <a {...{
          "href": "/geoip/importing-databases",
          "parentName": "p"
        }}>{`tutorials for importing CSV databases into SQL`}</a>
        {`.`}</p>
      <div {...{
        "id": "toc-checking-for-the-latest-release-date",
        "parentName": "div"
      }}><h3 {...{
          "id": "checking-for-the-latest-release-date",
          "parentName": "div"
        }}>{`Checking for the Latest Release Date`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`You can check the date of a given database’s latest release by issuing a HEAD
request for that database’s download permalink URL. The download permalink can
be found in the
`}
          <a {...{
            "href": "https://www.maxmind.com/en/accounts/current/geoip/downloads",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Download Databases`}</a>
          {`
section of your account portal.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`For example, using the permalink for the GeoIP2 City CSV database, you can issue
a `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`curl`}</inlineCode>
          {` command like the following:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-cli",
            "parentName": "pre"
          }}>{`curl -I -L -u YOUR_ACCOUNT_ID:YOUR_LICENSE_KEY 'https://download.maxmind.com/geoip/databases/GeoIP2-City-CSV/download?suffix=zip'
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`Or a `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`wget`}</inlineCode>
          {` command like the following:`}</p>
        <pre {...{
          "parentName": "div"
        }}><code {...{
            "className": "language-cli",
            "parentName": "pre"
          }}>{`wget -S --method HEAD --user=YOUR_ACCOUNT_ID --password=YOUR_LICENSE_KEY 'https://download.maxmind.com/geoip/databases/GeoIP2-City-CSV/download?suffix=zip'
`}</code></pre>
        <p {...{
          "parentName": "div"
        }}>{`Where `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`YOUR_ACCOUNT_ID`}</inlineCode>
          {` is a placeholder for your account ID and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`YOUR_LICENSE_KEY`}</inlineCode>
          {` is a placeholder for your license key.
`}
          <a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4407116112539-Using-License-Keys",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`Learn more about license keys on our knowledge base`}</a>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`In the response, you can check the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`last-modified`}</inlineCode>
          {` header for the file’s build
date, or you can check the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`content-disposition`}</inlineCode>
          {` header for the date that would
appear in the file name. These checks can be incorporated into your own script
or program, according to your needs.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`This method only issues a HEAD request, rather than a download request, so
running this check won’t count against your
`}
          <a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408216129947",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`daily database download limit`}</a>
          {`.`}</p></div></div>
    <div {...{
      "id": "toc-changes-to-file-size-between-updates"
    }}><h2 {...{
        "id": "changes-to-file-size-between-updates",
        "parentName": "div"
      }}>{`Changes to file size between updates`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`It is expected for database files to increase or decrease in size from time to
time between releases. As we incorporate data corrections and new data sources,
we will add entries to our databases. Sometimes IP ranges are taken out of use
and removed. You should develop your integration with the expectation that
changes to the size of our databases will occur.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`When we anticipate significant changes to the size of our databases, we will
notify customers by `}
        <a {...{
          "href": "/geoip/release-notes",
          "parentName": "p"
        }}>{`posting a release note`}</a>
        {`.`}</p></div>
    <div {...{
      "id": "toc-download-limits"
    }}><h2 {...{
        "id": "download-limits",
        "parentName": "div"
      }}>{`Download limits`}</h2>
      <p {...{
        "parentName": "div"
      }}><em {...{
          "parentName": "p"
        }}>{`MaxMind reserves the right to
`}
          <a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408216129947",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "em"
          }}>{`limit the number of downloads`}</a>
          {`
made within a limited period of time.`}</em></p></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      